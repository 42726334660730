// Auth.js
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const Auth = ({ children }) => {
  const isAuthenticated = window.localStorage.getItem("groomer__authToken");
  const navigate = useNavigate();
  var childrenTypeName = "pX";

  useEffect(() => {
    //console.log("Auth token:", isAuthenticated);
    if (process.env.REACT_APP_ENV === "prod") {
      console.log("children.type.name:", children.type.name);
      // childrenTypeName = children.type.name;
    }

    if (
      isAuthenticated &&
      (children.type.name === "LoginPage" || children.type.name === childrenTypeName)
    ) {
      //console.log("Navigating to /panel");
      navigate("/panel", { replace: true });
    } else if (!isAuthenticated && children.type.name !== "LoginPage") {
      //console.log("Navigating to /");
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate, children.type.name]);

  if (isAuthenticated) {
    return children;
  } else if (
    children.type.name === "LoginPage" ||
    children.type.name === childrenTypeName
  ) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};
