import * as React from "react";
import { IconButton, Box, Collapse } from "@mui/material";
import { IconButtonWithDisabledByRole } from "../ButtonDisabledByRole/withDisabledByRole";

import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function DeleteButton({ handleDelete }) {
  const [editMode, setEditMode] = React.useState(false);

  return (
    <Box
      className="wrapper"
      sx={{
        backgroundColor: "red",
        maxHeight: editMode ? 80 : 40,
        minWidth: 40,
        borderRadius: 5,
        transition:
          "max-height 0.3s ease-in-out, background-color 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Box>
        <IconButtonWithDisabledByRole
          aria-label="delete"
          sx={{ color: "white", height: "40px" }}
          onClick={() => {
            var newState = !editMode;
            setEditMode(newState);
          }}
        >
          {editMode ? <CloseIcon /> : <DeleteOutlineIcon />}
        </IconButtonWithDisabledByRole>

        <Collapse in={editMode}>
          {editMode ? (
            <IconButton
              aria-label="delete"
              sx={{ height: "40px" }}
              onClick={() => {
                handleDelete(null, true);
                var newState = !editMode;
                setEditMode(newState);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          ) : (
            ""
          )}
        </Collapse>
      </Box>
    </Box>
  );
}
